import 'core-js'
import 'regenerator-runtime/runtime'

import '../initializers/rails'
import '../initializers/stimulus';
import '../initializers/cocoon';
import '../initializers/turbolinks'
import '../initializers/vue'

import '../lib/application'

import '../controllers'
import '../components'

const images = require.context('../images/', true)

import { Controller } from '@stimulus/core'

import { mapKeys } from 'lodash'

type ZipData = [number, string | null, string | null, string | null]

interface Window {
  AjaxZipP: { zipdata(data: { [key: number]: ZipData }): void }
}
declare var window: Window

const eventName = 'ajaxzipp:zipdata:received'
const state: { [key: string]: 'loading' | 'loaded' } = {}
const cache: { [key: string]: ZipData } = {}

/*
 * AjaxZipP の置き換え。
 * target=postalcode.postalcode を郵便番号として、
 * target=postalcode.address の内容を自動入力する
 *
 * <div data-controller="postalcode">
 *   <input data-target="postalcode.postalcode" />
 *   <input data-target="postalcode.address" />
 *   <input data-target="postalcode.address" data-address-format="$2" />
 *   <select data-target="postalcode.address" data-address-format="$0">
 *     <option></option>
 *     <option value="32">島根県</option>
 *   </select>
 * </div>
 *
 * controller option
 *  insert-hyphen: 郵便番号にハイフンを自動的に付加する
 *  format: address のフォーマット (default: '$1$2$3$4')
 *    $0: 県コード '32'
 *    $00: 2桁県コード 左側を 0 でパディングする
 *    $1: 県名 '島根県'
 *    $2: 市町村名 '松江市'
 *    $3: 地域等 '北陵町'
 *    $4: 大字等 ''
 *
 * address option:
 *  address-format: controller のオプションと同じ
 */
export default class extends Controller {
  hasPostalcodeTarget: boolean
  postalcodeTarget: HTMLInputElement
  addressTargets: HTMLInputElement[]

  onReceive: EventListenerObject

  static targets = ['postalcode', 'address']

  initialize() {
    this.decode = this.decode.bind(this)
    this.onReceive = {
      handleEvent: this.receive.bind(this),
    }

    if (typeof window.AjaxZipP === 'undefined') {
      // zip-xxx.js がコールバックとして AjaxZipP.zipdata を呼ぶので定義する
      window.AjaxZipP = {
        zipdata: data => fireEvent(eventName, data),
      }
    }
  }

  connect() {
    document.addEventListener(eventName, this.onReceive, false)

    this.postalcodeTarget.addEventListener('input', this.decode, false)
    this.postalcodeTarget.addEventListener('keyup', this.decode, false)
  }

  disconnect() {
    document.removeEventListener(eventName, this.onReceive)
    if (this.hasPostalcodeTarget) {
      this.postalcodeTarget.removeEventListener('input', this.decode)
      this.postalcodeTarget.removeEventListener('keyup', this.decode)
    }
  }

  invalid() {
    this.postalcodeTarget.classList.add('is-invalid')
    this.postalcodeTarget.classList.remove('is-valid')
  }

  reset() {
    this.postalcodeTarget.classList.remove('is-invalid')
    this.postalcodeTarget.classList.remove('is-valid')
  }

  decode() {
    const value = this.postalcodeTarget.value
    if (!value) {
      this.reset()
      return
    }

    // 000-?0000 に前方一致しない場合は invalid
    if (!/^\d{0,3}-?\d{0,4}$/.test(value)) {
      this.invalid()
      return
    }

    // value から 3, 4 桁を取り出す
    let m, zip3, zip4
    if ((m = value.match(/^(\d{3})-?(\d{4}$)?/))) {
      zip3 = m[1]
      zip4 = m[2]
    }

    // 前半 3 桁が見つからないときは reset
    if (!zip3) {
      this.reset()
      return
    }

    // 前半 3 桁が決まった時点でデータのロードを開始する
    if (!state[zip3]) {
      this.reset()
      this.loadData(zip3)
      return
    }

    // 後半の 4 桁がないときは停止
    if (!zip4) {
      return
    }

    // loading or loaded
    if (state[zip3] === 'loaded') {
      const code = zip3 + zip4
      if (cache[code]) {
        // value にハイフンがなければハイフンを付ける
        if (this.insertHyphen && value === code) {
          this.postalcodeTarget.value = `${zip3}-${zip4}`
        }
        this.setData(cache[code])
        this.reset()
      } else {
        // 該当するデータがなければ invalid
        this.invalid()
      }
    } else {
      // データのロード待ち
      setTimeout(this.decode, 100)
    }
  }

  loadData(zip3: string) {
    // load postal data
    state[zip3] = 'loading'

    // load data with jsonp
    const s = document.createElement('script')
    s.setAttribute(
      'src',
      `${this.jsonpPath}/zip-${zip3}.js?t=${new Date().getTime()}`
    )
    s.setAttribute('charset', 'utf-8')
    s.onload = () => {
      state[zip3] = 'loaded'
      this.decode()
    }
    document.body.appendChild(s)
  }

  setData(data: ZipData) {
    Array.from(this.addressTargets, addressTarget => {
      const format = addressTarget.dataset.addressFormat || this.format
      const value = format
        .replace('$00', data[0] < 10 ? '0' + data[0] : '' + data[0])
        .replace('$0', '' + data[0])
        .replace('$1', PREFMAP[data[0]])
        .replace('$2', data[1] || '')
        .replace('$3', data[2] || '')
        .replace('$4', data[3] || '')
      if (addressTarget.value && addressTarget.value.indexOf(value) === 0) {
        // 前半が一致する場合は何もしない
        return
      }
      addressTarget.value = value
      addressTarget.focus()
    })
  }

  receive(evt: CustomEvent) {
    const data = mapKeys(evt.detail, (v, k) => '' + k)
    Object.assign(cache, data)
  }

  get jsonpPath() {
    return this.data.get('jsonpPath') || JSONP_PATH
  }

  get insertHyphen() {
    return this.data.get('insertHyphen') !== 'false'
  }

  get format() {
    return this.data.get('format') || '$1$2$3$4'
  }
}

// default data path
const JSONP_PATH = 'https://asp.pc-egg.com/ajaxzipp/jsonp'

const PREFMAP = [
  '',
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
]

function fireEvent(eventName: string, data: any) {
  let event
  try {
    event = new CustomEvent(eventName, {
      detail: data,
    })
  } catch (e) {
    event = document.createEvent('CustomEvent')
    event.initCustomEvent(eventName, false, false, data)
  }
  document.dispatchEvent(event)
}

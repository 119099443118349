// activestorage direct uploading
import $ from 'jquery'

const notices: { [key: string]: any } = {}

addEventListener('direct-upload:initialize', (event: CustomEvent) => {
  const { detail } = event
  const { id, file } = detail
  const notice = $.notify(
    {
      title: file.name,
      message: '待機中...',
    },
    {
      allow_dismiss: true,
      showProgressbar: true,
    }
  )
  notices[id] = notice
})

addEventListener('direct-upload:start', (event: CustomEvent) => {
  const { id } = event.detail
  const notice = notices[id]
  if (notice) {
    notice.update('message', 'アップロード中です...')
  }
})

addEventListener('direct-upload:progress', (event: CustomEvent) => {
  const { id, progress } = event.detail
  const notice = notices[id]
  if (notice) {
    notice.update('progress', progress)
  }
})

addEventListener('direct-upload:error', (event: CustomEvent) => {
  event.preventDefault()
  const { id, error } = event.detail
  const notice = notices[id]
  if (notice) {
    notice.update('type', 'danger')
    notice.update('message', `エラー: ${error}`)
    notice.update('progress', 100)
    delete notices[id]
  }
})

addEventListener('direct-upload:end', (event: CustomEvent) => {
  const { id } = event.detail
  const notice = notices[id]
  if (notice) {
    notice.update('type', 'success')
    notice.update('message', 'アップロード完了')
    notice.update('progress', 100)
    delete notices[id]
  }
})

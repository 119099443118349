import { Controller } from "stimulus";

import autosize from "autosize";

export default class extends Controller {
  elements: Element | NodeList = null;

  connect() {
    if (this.element instanceof HTMLTextAreaElement) {
      this.elements = autosize(this.element);
    } else {
      this.elements = autosize(this.element.querySelectorAll("textarea"));
    }
  }

  disconnect() {
    if (this.elements instanceof Element) {
      autosize.destroy(this.elements);
    } else if (this.elements instanceof NodeList) {
      autosize.destroy(this.elements);
    }
    this.elements = null;
  }
}
